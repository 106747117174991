/* HomePage.css */
.fullscreen-image {
  
    background-image: url('../assets/AC787BLUESKY.jpg');
    background-size: cover; /* Ensures the image covers the entire container */
    background-position: center; /* Keeps the plane centered */
    background-repeat: no-repeat;
    height: 100vh; /* Full viewport height */
    width: 100%;
  }
  

.homePageContainer {
    display: flex;
    position: absolute; 
    flex-direction: column; /* Stack header and content vertically */
    width: 100vw;
    min-height: 100vh; /* Ensure it covers the full viewport */
}