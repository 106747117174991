/* Portfolio.css */
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
    overflow-x: hidden;
}

.aboutpage-container {
  height: 100vh;
  display: flex;
  flex-direction: column; /* Ensures content flows properly */
  margin: 0;
  padding: 0;
}
  
.aboutpage-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1; /* Allows it to fill available space */
  text-align: center;
}

.snowboy {
  /*max-width: 90%; /* Reduce size further on smaller screens */
  max-width: 90%; /* Ensures the image doesn't exceed 90% of the container's width */
  max-height: 70vh; /* Limits the image height to 70% of the viewport height */
  height: auto;
  margin: 20px auto; /* Add some space around the image */
}
  
@media (max-width: 768px) {
  .aboutpage-content {
    align-items: center;
  }

  .snowboy {
    max-width: 90%; /* Reduce size further on smaller screens */
    margin: 20px auto; /* Add some space around the image */
  }
}