.storePageContainer {
  display: flex;
  flex-direction: column; /* Stack header and content vertically */
  min-height: 100vh; /* Ensure it covers the full viewport */
}

.otherHeader {
  position: relative; /* Ensure header occupies its own space */
  z-index: 1;
  width: 100%;
}

.portfolio-content {
  text-align: center;
  flex-grow: 1; /* Let the content take up the remaining space */
}

@media (max-width: 768px) {

}