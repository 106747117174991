/* PortfolioHelper.css */

.image-grid {
    background-color: #0d1b2a; /* Dark background for contrast */
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two side-by-side columns */
    gap: 18px; /* Space between images */
    padding: 40px;
  }
  
  .image-item {
    overflow: hidden;
    transition: transform 0.3s ease;
  }
  
  .image-item img {
    width: 100%;
    height: auto;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  


  /* Media query for mobile screens */
@media (max-width: 900px) {
    .image-grid {
        grid-template-columns: 1fr; /* Single column layout */
        gap: 20px; /* Adjust gap for smaller screens if needed */
        padding: 20px;
    }
}