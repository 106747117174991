.instagram-gallery-container {
  padding: 30px;
  padding-bottom: 10px;
  padding-top: 10px;
  background-color: #0d1b2a; /* Dark background color for contrast */
  color: #f0b428; /* Title color */
  text-align: center;
}

.instagram-title {
  font-size: 24px;
  letter-spacing: 1px;
  color: #f0b428;
}

/* Horizontal scrolling row */
.instagram-grid {
  display: flex;
  overflow-x: auto; /* Allows horizontal scrolling */
  white-space: nowrap; /* Prevents wrapping */
  gap: 15px; /* Space between images */
  padding: 10px 0;
}

.instagram-image-wrapper {
  flex: 0 0 auto; /* Prevent images from shrinking */
  width: 200px; /* Set a fixed width for each image */
  height: 200px; /* Set a fixed height for each image */
  overflow: hidden;
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.instagram-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

/*.instagram-image-wrapper:hover {
  transform: scale(1.05);
}
*/