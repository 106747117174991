/* Footer.css */
.footer-container {
    background-color: black; /* Dark background for contrast */
    color: #f0b428; /* Text color */
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 16px;
  }
  
  .footer-container a {
    color: #f0b428; /* Link color */
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .footer-container a:hover {
    color: #ffffff; /* Link hover color */
  }
  
  .footer-name {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .footer-email, .footer-social {
    margin: 5px 0;
  }
  
/* Center the social icons in a row with spacing */
.footer-social {
    font-size: 24px;
    display: flex;
    justify-content: center; /* Center the icons */
    gap: 15px; /* Space between each icon */
    margin-top: 10px;
  }