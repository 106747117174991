/* OtherHeader.css */
.body {
  overflow-x: hidden;
}

/* Base header styling */
.otherHeader {
  display: flex;
  position: fixed; /* Keep it relative to allow positioning adjustments */
  width: 100vw;

  align-items: center;
  justify-content: center;
  flex-direction: column;

  top: 0;
  padding-top: 40px;
  padding-bottom: 120px;

  background-image: url('../assets/AC787BLUESKYLEFT.jpg'); /* Same as portfolio */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 1; /* Ensure header is on top */
  
}

/* Header text styling */
.otherName {
  position: relative;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 36px;
  cursor: pointer;
  padding-bottom: 40px;
  letter-spacing: 4px;
}

.otherName a {
  text-decoration: none; /* Remove underline */
  color: inherit; /* Inherit text color */
}

.otherTemporary {
  font-weight: normal; /* Normal weight for TEMPORARY */
}

.otherWindow {
  font-weight: bold; /* Bold weight for WINDOW */
}




/* Navigation Links */
.otherNavLinks {
  display: flex;
  gap: 70px; /* Space between each link */
  list-style: none;
  padding: 0;
  margin: 0;
}

.otherNavLinks li a {
  text-decoration: none;
  color: black; /* Set link color */
  font-size: 18px;
  letter-spacing: 1.6px;
  transition: color 0.3s ease;
}

.otherNavLinks li a:hover {
  color: red; /* Change color on hover */
  transform: scale(1.05); /* Slight scale-up on hover */
}

.menu-overlay {
  display: none; /* Initially hide navigation links */
}

.menu-btn {
  display: none;
  font-size: 24px;
  cursor: pointer;
  position: absolute;
  right: 10px; /* Adjust as needed */
}






/* Mobile-specific adjustments */
@media (max-width: 768px) {

  .otherHeader {
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 12vh;
    padding: 0; /* Remove padding */
  }



  .bandN{
    width: 95vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px;
    margin: 20px;
  }

  .otherName {
    margin-top: 0px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0px;
    font-size: 20px; /* Smaller font for mobile */
  }

  .menu-btn {
    font-size: 28px;
    display: flex; /* Show menu button on mobile */
    align-items: center; /* Center aligns the hamburger icon vertically */
    margin-top: 0; /* Remove any top margin */
    align-items: flex-end;
    justify-content: flex-end;
    margin: 0px;

  }

  .otherNavLinks {
    display: none; /* Initially hide navigation links */
  }

  .otherNavLinks .home {
    display: none;
  }

  .menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent background */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease; /* Smooth transition */
    z-index: 10; /* Make sure the overlay is above everything */
  }
  
  /* Show the overlay when active */
  .menu-overlay.active {
    opacity: 1;
    visibility: visible;
  }
  
  .menu-overlay a {
    color: white;
    font-size: 24px;
    text-decoration: none;
    margin: 20px 0; /* Space between menu items */
  }
  
  .menu-overlay a:hover {
    color: red; /* Add a hover effect */
  }
}
